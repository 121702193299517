<template>
    <div class="relative w-screen h-screen overflow-hidden bg-gray-700">
        <div class="absolute top-0 left-0 w-full flex space-x-1 px-4 py-2 z-10">
            <div v-for="(media, index) in slides" :key="index" class="h-1 bg-gray-400 rounded flex-1 relative">
                <div class="absolute top-0 left-0 h-full bg-white rounded transition-all" :style="{
                    width: index === currentSlide ? `${progress}%` : index < currentSlide ? '100%' : '0%',
                }"></div>
            </div>
        </div>

        <!-- Progress Bar ala Instagram 
        <div v-if="progressBarWidth" class="absolute top-4 left-1/2 transform -translate-x-1/2 flex space-x-1 px-3"
            :style="{ width: `${progressBarWidth}px` }">
            <div v-for="(story, index) in stories" :key="index" class="h-1 bg-gray-500 flex-1 relative ">
                <div class="absolute top-0 left-0 h-full bg-white transition-all"
                    :style="{ width: `${fullProgress[index]}%` }"></div>
            </div>
        </div>
        -->

        <!-- Slider -->
        <div class="flex transition-transform duration-500 ease-out"
            :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
            <div v-for="(media, index) in slides" :key="index"
                class="w-full h-screen flex-shrink-0 flex justify-center items-center" ref="slide">
                <!-- Video -->
                <video v-if="media.type === 'video'" :src="media.src" autoplay
                    class="max-h-full max-w-full object-contain" ref="videos"></video>
                <!-- Image -->
                <img v-else :src="media.src" alt="Slide Image" class="max-h-full max-w-full object-contain" ref="width"/>
            </div>
        </div>

        <!-- Navigation -->
        <div class="absolute inset-0 flex justify-between items-center">
            <button class="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-900 text-white p-3 rounded-full"
                @click="prevSlide">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
                </svg>
            </button>
            <button v-if="currentSlide < slides.length - 1"
                class="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-900 text-white p-3 rounded-full"
                @click="nextSlide">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                </svg>
            </button>
        </div>

        <!-- Final Button -->
        <div v-if="currentSlide === slides.length - 1"
            class="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-1">
            <button class="bg-sky-500 text-white font-general-bold py-2 px-4 rounded-lg transition ease-in duration-150"
                @click="downloadImage">
                Unduh dan bagikan!
            </button>
            <a href="https://open.spotify.com/playlist/5pneWX7yINt2mZvdaOTB5H?si=9c07028f8ffe4651&pt=fc3b52fc16fe547947491dab0d0b091d"
                target="_blank"
                class="bg-[#1db954] text-white font-general-bold py-2 px-4 rounded-lg transition ease-in duration-150">
                Cek Playlist
            </a>
            <router-link to="/what-song-oniel-play"
                class="bg-indigo-50 text-sky-500 font-general-bold py-2 px-4 rounded-lg transition ease-in duration-150">
                Lihat List Lengkapnya
            </router-link>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            currentSlide: 0,
            progress: 0,
            slides: [
                { type: 'video', src: require('@/assets/images/testing/1-welcome.mp4') },
                { type: 'video', src: require('@/assets/images/testing/2-minuteslive.mp4') },
                { type: 'video', src: require('@/assets/images/testing/3-infototal.mp4') },
                { type: 'video', src: require('@/assets/images/testing/4-topgenre.mp4') },
                { type: 'video', src: require('@/assets/images/testing/5-topartist.mp4') },
                { type: 'video', src: require('@/assets/images/testing/6-artistlist.mp4') },
                { type: 'video', src: require('@/assets/images/testing/7-topsong.mp4') },
                { type: 'video', src: require('@/assets/images/testing/8-songlist.mp4') },
                { type: 'video', src: require('@/assets/images/testing/9-worldwide.mp4') },
                { type: 'video', src: require('@/assets/images/testing/10-newarrival.mp4') },
                { type: 'image', src: require('@/assets/images/testing/FinalWrapped.png') },
            ],
            intervalId: null,
            mediaWidth: 0,  // Menyimpan lebar media yang ditampilkan
        };
    },
    watch: {
        currentSlide() {
            this.updateVideoPlayback();
            this.resetProgress();
            this.updateMediaWidth();  // Update lebar media saat slide berubah
        },
    },
    mounted() {
        this.updateVideoPlayback();
        this.updateMediaWidth();  // Set lebar media pada saat awal
        this.startProgress();
    },
    beforeUnmount() {
        clearInterval(this.intervalId);
    },
    methods: {
        nextSlide() {
            if (this.currentSlide < this.slides.length - 1) {
                this.currentSlide++;
            } else {
                this.stopProgress(); // Hentikan progress di slide terakhir
            }
        },
        prevSlide() {
            if (this.currentSlide > 0) {
                this.currentSlide--;
            }
        },
        updateVideoPlayback() {
            const videos = this.$refs.videos;
            if (videos) {
                videos.forEach((video, index) => {
                    if (index === this.currentSlide) {
                        video.play();
                    } else {
                        video.pause();
                        video.currentTime = 0;
                    }
                });
            }
        },
        resetProgress() {
            if (this.currentSlide === this.slides.length - 1) {
                this.progress = 100; // Langsung penuh di slide terakhir
                this.stopProgress();
            } else {
                this.progress = 0;
                this.startProgress();
            }
        },
        startProgress() {
            clearInterval(this.intervalId); // Hentikan interval sebelumnya
            this.intervalId = setInterval(() => {
                if (this.progress < 100) {
                    this.progress += 100 / 15; // 15 detik untuk mencapai 100%
                } else {
                    this.nextSlide();
                }
            }, 1000); // Update setiap detik
        },
        stopProgress() {
            clearInterval(this.intervalId); // Hentikan interval
        },
        downloadImage() {
            const currentStory = this.slides[this.currentSlide];
            // Buat elemen tautan untuk download
            const link = document.createElement('a');
            link.href = currentStory.src; // URL gambar
            link.download = `onielwrapped2024-${this.currentIndex + 1}.jpg`; // Nama file saat diunduh
            link.click(); // Jalankan aksi klik untuk memulai unduhan
        },
        updateMediaWidth() {
            const mediaElement = this.$refs.slides
            if (mediaElement) {
                this.mediaWidth = mediaElement.offsetWidth; // Mengambil lebar elemen media yang ditampilkan
            }
        },
    },
};
</script>


<!--
slides: [
                { type: 'video', src: require('@/assets/images/testing/1-welcome.mp4') },
                { type: 'video', src: require('@/assets/videos/onielwrapped1.mp4') },
                { type: 'image', src: require('@/assets/images/testing/FinalWrapped.png') },
            ],
-->
