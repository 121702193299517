<template>
    <div class="w-full mx-auto mt-10 p-5">
        <div v-for="(item, index) in items" :key="index" class="rounded-lg mb-2">
            <div class="bg-gray-200 rounded-lg p-4 cursor-pointer" @click="toggle(index)">
                <h2 class="text-lg font-semibold">{{ item.title }}</h2>
            </div>
            <div v-if="activeIndex === index" class="p-4">
                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 p-5 sm:gap-10">
                    <div
                        class="mt-5 h-84 border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
                        <div class="flex flex-row justify-center">
                            <h3 class="font-general-semibold text-2xl font-semibold mt-2 mb-3 px-4">Total Live</h3>
                        </div>
                        <p class="text-bold text-6xl mt-2 text-md p-5">{{ item.live }}</p>
                    </div>
                    <div
                        class="mt-5 h-84 border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
                        <div class="flex flex-row justify-center">
                            <h3 class="font-general-semibold text-2xl font-semibold mt-2 mb-3 px-4">Total Minutes Live
                            </h3>
                        </div>
                        <p class="text-bold text-6xl mt-2 text-md p-5">{{ item.minutes }}</p>
                    </div>
                    <div
                        class="mt-5 h-84 border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
                        <div class="flex flex-row justify-center">
                            <h3 class="font-general-semibold text-2xl font-semibold mt-2 mb-3 px-4">Total Song
                            </h3>
                        </div>
                        <p class="text-bold text-6xl mt-2 text-md p-5">{{ item.song }}</p>
                    </div>
                    <div
                        class="mt-5 h-84 border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
                        <div class="flex flex-row justify-center">
                            <h3 class="font-general-semibold text-2xl font-semibold mt-2 mb-3 px-4">Total Artist
                            </h3>
                        </div>
                        <p class="text-bold text-6xl mt-2 text-md p-5">{{ item.artist }}</p>
                    </div>
                </div>
                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-5 sm:gap-10">
                    <div
                        class="mt-5 h-84 border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
                        <div class="flex flex-row justify-center">
                            <h3 class="font-general-semibold text-2xl font-semibold mt-2 mb-3 px-4">Top 5 Most Played
                                Song</h3>
                        </div>
                        <p class="text-left text-bold text-2xl mt-2 ml-10">1. {{ item.song1 }}</p>
                        <p class="text-left text-bold text-2xl mt-2 ml-10">2. {{ item.song2 }}</p>
                        <p class="text-left text-bold text-2xl mt-2 ml-10">3. {{ item.song3 }}</p>
                        <p class="text-left text-bold text-2xl mt-2 ml-10">4. {{ item.song4 }}</p>
                        <p class="text-left text-bold text-2xl mt-2 ml-10">5. {{ item.song5 }}</p>
                    </div>
                    <div
                        class="mt-5 h-84 border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
                        <div class="flex flex-row justify-center">
                            <h3 class="font-general-semibold text-2xl font-semibold mt-2 mb-3 px-4">Top 5 Most Played
                                Artist</h3>
                        </div>
                        <p class="text-left text-bold text-2xl mt-2 ml-10">1. {{ item.artist1 }}</p>
                        <p class="text-left text-bold text-2xl mt-2 ml-10">2. {{ item.artist2 }}</p>
                        <p class="text-left text-bold text-2xl mt-2 ml-10">3. {{ item.artist3 }}</p>
                        <p class="text-left text-bold text-2xl mt-2 ml-10">4. {{ item.artist4 }}</p>
                        <p class="text-left text-bold text-2xl mt-2 ml-10">5. {{ item.artist5 }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeIndex: null,
            items: [
                {
                    title: "IDN Live Challenge Dec'23 - Jan'24", live: '30', minutes: '1293', song: '162', artist: '109', song5: 'Ima ~Even If The World Ends Tomorrow~', song4: 'Christmas Tree', song3: 'Bad', song2: 'Episode', song1: 'Ordinary Confession', artist5: 'JKT48', artist4: 'Bruno Mars', artist3: 'Wave To Earth', artist2: 'Lee Mujin', artist1: 'Seventeen'
                },
                { title: "Showroom Recap: 2023", live: '31', minutes: '2102', song: '252', artist: '121',
                song5: 'As It Was', song4: 'Life Goes On', song3: 'Darl+ing', song2: 'HOT', song1: 'Rock With You',
            artist5: 'Newjeans', artist4: 'Sheila on 7', artist3: 'JKT48', artist2: 'Seventeen', artist1: 'BTS'
                },
                { title: "Showroom Recap: 2022", live: '40', minutes: '2850', song: '350', artist: '190', 
                    song5: 'Fake Love', song4: 'Life Goes On', song3: 'Enchanted', song2: 'Dandelions', song1: 'Permission to Dance',
            artist5: 'Sheila on 7', artist4: 'ENHYPEN', artist3: 'JKT48', artist2: 'Tulus', artist1: 'BTS',
                },
                { title: "Showroom Challenge: 2021", live: '80', minutes: '3952', song: '203', artist: '112', 
                    song5: 'Dynamite', song4: "You're Gonna Live Forever in Me", song3: 'Save ME', song2: 'Just One Day', song1: 'Permission to Dance',
            artist5: 'Idgitaf', artist4: 'Jungkook', artist3: 'HIVI!', artist2: 'Tulus', artist1: 'BTS'
                },
                { title: "Showroom Recap: 2021", live: '125', minutes: '7989', song: '319', artist: '170',
                    song5: 'Save ME', song4: 'Pelangi', song3: 'C.H.R.I.S.Y.E.', song2: 'Permission to Dance', song1: 'Just One Day',
            artist5: 'Naif', artist4: 'HIVI!', artist3: 'JKT48', artist2: 'Tulus', artist1: 'BTS'
                },
                { title: "Showroom Recap: 2020", live: '5', minutes: '468', song: '-', artist: '-',
                    song5: '-', song4: '-', song3: '-', song2: '-', song1: '-',
            artist5: '-', artist4: '-', artist3: '-', artist2: '-', artist1: '-'
                },
            ]
        };
    },
    methods: {
        toggle(index) {
            this.activeIndex = this.activeIndex === index ? null : index;
        }
    }
};
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}
</style>