<template>
  <div>
    <!-- Main modal -->
    <div ref="modal" id="static-modal" tabindex="-1" aria-hidden="true"
      class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div class="relative p-4 w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <!-- Modal header -->
          <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              The General Election has Begun!
            </h3>
            <button @click="closeModal" type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="static-modal">
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-4 md:p-5 space-y-4">
            <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Oniel is taking steps to realize his dreams! With your help, she will go further and shine brighter. Let's
              collaborate to realize one of his dreams and step together towards the horizon in a timely manner. </p>
            <p class="text-3xl font-bold leading-relaxed text-black dark:text-white">#ONTIME</p>
            <button type="button"
              class="text-white bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600">2nd
              Pre-result: 20.902 Vote (14th Rank)</button>
          </div>
          <!-- Modal footer -->
          <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 space-x-3">
            <a href="https://ssk.jkt48.com/2024/id" target="_blank">
              <button @click="closeModal" data-modal-hide="static-modal" type="button"
                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Go
                To Vote!</button>
            </a>
            <router-link to="/general-election"
              class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
              aria-label="Sousenkyo">
              <button @click="closeModal" data-modal-hide="static-modal" type="button"
                class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Get
                to Know</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.checkModalStatus(); // Cek status modal saat halaman dimuat
  },
  methods: {
    checkModalStatus() {
      const modalShown = localStorage.getItem('modalShown');
      if (!modalShown) {
        this.openModal(); // Buka modal jika belum pernah ditampilkan
      }
    },
    toggleModal() {
      const modal = this.$refs.modal;
      modal.classList.toggle('hidden'); // Toggle visibility modal
    },
    openModal() {
      const modal = this.$refs.modal;
      modal.classList.remove('hidden'); // Membuka modal
    },
    closeModal() {
      const modal = this.$refs.modal;
      modal.classList.add('hidden'); // Menutup modal
      localStorage.setItem('modalShown', 'true'); // Simpan status modal ditampilkan
    }
  }
}
</script>

<style scoped>
/* Optional styling */
</style>