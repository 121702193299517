<script>
export default {
	props: ['showModal', 'isOpen'],
};
</script>

<template>
	<!-- Header links -->
	<div
		:class="isOpen ? 'block' : 'hidden'"
		class="m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none border border-black dark:border-white"
	>
		<router-link
			to="/botoniel"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:bg-indigo-600 hover:text-white dark:hover:bg-indigo-300 dark:hover:text-black border-t-2 px-4 py-3 sm:pt-2 sm:border-t-0 transition duration-200"
			aria-label="BotOniel"
			>BotOniel</router-link
		>

		<router-link
			to="/what-song-oniel-play"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:bg-indigo-600 hover:text-white dark:hover:bg-indigo-300 dark:hover:text-black border-t-2 px-4 py-3 sm:pt-2 sm:border-t-0 transition duration-200"
			aria-label="WSOP"
			>What Song Oniel Play?</router-link
		>
		<router-link
			to="/onieland-recap"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:bg-indigo-600 hover:text-white dark:hover:bg-indigo-300 dark:hover:text-black border-t-2 px-4 py-3 sm:pt-2 sm:border-t-0 transition duration-200"
			aria-label="Onieland"
			>#OnieLand Recap</router-link
		>
		<router-link
			to="/general-election"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:bg-indigo-600 hover:text-white dark:hover:bg-indigo-300 dark:hover:text-black border-t-2 px-4 py-3 sm:pt-2 sm:border-t-0 transition duration-200"
			aria-label="Onieland"
			>General Election 2024</router-link
		>
	</div>
</template>

<style lang="scss" scoped></style>
