<template>
    <section class="dark:text-gray-800">
        <div class="container max-w-5xl px-4 py-12 mx-auto">
            <div class="grid gap-4 mx-4 sm:grid-cols-12">
                <div class="col-span-12 sm:col-span-3">
                    <div
                        class="text-center sm:text-left mb-14 before:block before:w-24 before:h-3 before:mb-5 before:rounded-md before:mx-auto sm:before:mx-0 before:bg-violet-600">
                        <h3 class="text-3xl font-semibold text-gray-500 dark:text-white">Bagaimana cara menggunakan Hak Suara?</h3>
                        <span class="text-sm font-bold tracking-wider text-black dark:text-gray-400">pada Pemilihan Member Single Ke-26 JKT48</span>
                    </div>
                </div>
                <div class="relative col-span-12 px-4 space-y-6 sm:col-span-9">
                    <div
                        class="col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-300">
                        <div
                            class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-violet-600">
                            <h3 class="text-xl text-left font-semibold tracking-wide text-gray-500 dark:text-white">Pergi ke Website Pemungutan Suara (SSK.JKT48.COM)</h3>
                            <p class="text-left mt-3 text-gray-800 dark:text-white">Kunjungi website ssk.jkt48.com dan pilih menu "Vote". Pastikan anda telah memiliki suara yang bisa didapatkan dengan membeli produk JKT48 berbonus kode vote</p>
                            <img src="@/assets/images/howtovote/1.png" class="hover:scale-150 ease-in duration-150">
                        </div>
                        <div
                            class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-violet-600">
                            <h3 class="text-left text-xl font-semibold tracking-wide text-gray-500 dark:text-white">Tambahkan Suaramu untuk Oniel!</h3>
                            <p class="text-left mt-3 text-gray-800 dark:text-white">Jika kode valid, anda akan diarahkan menuju halaman list member yang berpartisipasi. Cari nama "Cornelia Vanisa" dan masukkan suara yang dimiliki dengan input angka atau menggunakan counter button. Sistem akan mengkonfirmasi suara yang telah anda berikan.</p>
                                <img src="@/assets/images/howtovote/2.png" class="hover:scale-150 ease-in duration-150">
                        </div>
                        <div
                            class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-violet-600">
                            <h3 class="text-left text-xl font-semibold tracking-wide text-gray-500 dark:text-white">Suara anda Telah Dikirimkan!</h3>
                            <p class="text-left mt-3 text-black dark:text-white">Klik "Vote" untuk mengirimkan suara yang telah anda input. Selamat! Mari menunggu pengumuman selanjutnya hingga final. Semoga suara kita bisa membantu Oniel mewujudkan mimpinya</p>
                                <img src="@/assets/images/howtovote/3.png" class="hover:scale-150 ease-in duration-150">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>