<script>
import feather from 'feather-icons';
import FooterCopyright from './FooterCopyrightSSK.vue';
import { socialLinks } from '../../data/socialLinks';

export default {
	components: { FooterCopyright },
	data() {
		return {
			socials: socialLinks,
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<div class="container mx-auto pt-5 pb-8 mt-5">
			<!-- Footer copyright -->
			<FooterCopyright />
	</div>
</template>

<style scoped>
</style>
