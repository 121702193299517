<template>
    <div ref="chart" style="width: 100%; height: 750px;"
        class="bg-white border border-indigo-200 dark:border-ternary-dark rounded-xl">
    </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    name: 'MapChart',
    data() {
        return {
            chart: null,
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
        // Fetch GeoJSON data for the map
        const geoJsonResponse = await fetch('https://raw.githubusercontent.com/apache/echarts/master/map/json/world.json');
        const geoJson = await geoJsonResponse.json();

        // Fetch data per country
        const dataResponse = await fetch('https://your-api-endpoint.com/path/to/country-data');
        const countryData = await dataResponse.json();

        this.initChart(geoJson, countryData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
            // Load GeoJSON data for the map
            const geoJson = await import('@/components/maps/world.json');
            echarts.registerMap('world', geoJson);

            this.chart = echarts.init(this.$refs.chart);
            this.chart.setOption({
                title: {
                    text: 'Peta Persebaran Asal Negara Lagu #WhatSongOnielPlay',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}: {c}',
                },
                visualMap: {
                    min: 0,
                    max: 1000,
                    text: ['High', 'Low'],
                    realtime: false,
                    calculable: true,
                    inRange: {
                        color: ['lightskyblue', 'yellow', 'orangered'],
                    },
                },
                series: [
                    {
                        name: 'Peta Persebaran Asal Negara Lagu #WhatSongOnielPlay',
                        type: 'map',
                        map: 'world',
                        roam: true,
                        emphasis: {
                            label: {
                                show: true,
                            },
                        },
                        data: [
                            { name: 'Korea', value: 620 },
                            { name: 'Indonesia', value: 610 },
                            { name: 'United States', value: 282 },
                            { name: 'United Kingdom', value: 116 },
                            { name: 'Canada', value: 26 },
                            { name: 'Thailand', value: 17 },
                            { name: 'Australia', value: 13 },
                            { name: 'Japan', value: 13 },
                            { name: 'Philippines', value: 9 },
                            { name: 'Ireland', value: 7 },
                            { name: 'Malaysia', value: 6 },
                            { name: 'Jamaica', value: 4 },
                            { name: 'Norway', value: 3 },
                            { name: 'Singapore', value: 3 },
                            { name: 'Sweden', value: 3 },
                            { name: 'Armenia', value: 1 },
                            { name: 'Denmark', value: 1 },
                            { name: 'France', value: 1 },
                            { name: 'New Zealand', value: 1 },
                            { name: 'Russia', value: 1 },
                        ],
                    },
                ],
            });
        },
    },
    beforeUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    },
};
</script>

<style scoped>
/* Tambahkan style jika diperlukan */
</style>