<template>
    <!-- App Header -->
    <AppHeader class="items-center mb-24 px-10" /> <!-- hapus mt-24 setelah banner dihapus -->

    <div class="container mx-auto">
        <div class="text-center border-b border-primary-light dark:border-secondary-dark">
            <p
                class="font-general-semibold text-2xl sm:text-5xl font-semibold text-ternary-dark dark:text-ternary-light mb-4">
                BotOniel Jokes Finder
            </p>
            <h3
                class="font-general-regular text-center text-secondary-dark dark:text-ternary-light text-md sm:text-xl font-normal mb-4">
                Cari jokes Oniel favoritmu di sini!
            </h3>
            <div class="flex justify-center gap-5 mb-10 p-5">
                <router-link to="/botoniel"
                    class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
                    <i data-feather="book" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
                    <span class="text-sm sm:text-lg font-general-medium duration-100">Bot Versi Teks</span>
                </router-link>
                <router-link to="/botoniel/video"
                    class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
                    <i data-feather="video" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
                    <span class="text-sm sm:text-lg font-general-medium duration-100">Bot Versi Video</span>
                </router-link>
                <router-link to="/botoniel/quote"
                    class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
                    <i data-feather="message-square" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
                    <span class="text-sm sm:text-lg font-general-medium duration-100">Cari Quote Oniel</span>
                </router-link>
                <a href="https://t.me/botnielbot" target="_blank"
                    class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
                    <i data-feather="send" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
                    <span class="text-sm sm:text-lg font-general-medium duration-100">Akses Bot Telegram</span></a>

            </div>
        </div>
        <div class="p-5 w-full mx-auto space-y-6">
            <div class="flex items-center space-x-4">
                <input type="text" v-model="searchQuery" placeholder="Masukkan kata kunci jokes Oniel"
                    @keyup.enter="fetchData"
                    class="w-full font-general-medium pl-3 pr-1 sm:px-4 py-2.5 border-1 border-gray-200 dark:border-secondary-dark rounded-lg text-md sm:text-lg bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light" />
                <button class="bg-primary-light dark:bg-ternary-dark p-2.5 shadow-sm rounded-xl cursor-pointer"
                    @click="fetchData">
                    <i data-feather="search" class="text-ternary-dark dark:text-ternary-light"></i>
                </button>
            </div>

            <!-- Menampilkan Kartu Data Hasil Pencarian -->
            <div v-if="paginatedResults.length" class="space-y-4">
                <div v-for="(item, index) in paginatedResults" :key="index"
                    class="p-4 border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
                    <h2 class="font-general-semibold text-xl font-semibold mt-2 mb-3 px-4 text-black dark:text-white">{{
                        item.jokes }}</h2>
                    <p class="text-black dark:text-white">{{ item.event }}</p>
                    <p class="text-sm text-black dark:text-white">Tanggal: {{ item.date }}</p>
                    <a v-if="item.link" :href="item.link" target="_blank" class="mr-2">
                        <svg class="h-12 w-6 inline-block" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 461.001 461.001"
                            xml:space="preserve">
                            <g>
                                <path style="fill:#F61C0D;" d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728
		c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137
		C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607
		c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z" />
                            </g>
                        </svg>
                    </a>
                    <a v-if="item.linkx" :href="item.linkx" target="_blank" class="mr-2">
                        <svg class="h-12 w-6 inline-block" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 410.155 410.155"
                            xml:space="preserve">
                            <path style="fill:#76A9EA;" d="M403.632,74.18c-9.113,4.041-18.573,7.229-28.28,9.537c10.696-10.164,18.738-22.877,23.275-37.067
	l0,0c1.295-4.051-3.105-7.554-6.763-5.385l0,0c-13.504,8.01-28.05,14.019-43.235,17.862c-0.881,0.223-1.79,0.336-2.702,0.336
	c-2.766,0-5.455-1.027-7.57-2.891c-16.156-14.239-36.935-22.081-58.508-22.081c-9.335,0-18.76,1.455-28.014,4.325
	c-28.672,8.893-50.795,32.544-57.736,61.724c-2.604,10.945-3.309,21.9-2.097,32.56c0.139,1.225-0.44,2.08-0.797,2.481
	c-0.627,0.703-1.516,1.106-2.439,1.106c-0.103,0-0.209-0.005-0.314-0.015c-62.762-5.831-119.358-36.068-159.363-85.14l0,0
	c-2.04-2.503-5.952-2.196-7.578,0.593l0,0C13.677,65.565,9.537,80.937,9.537,96.579c0,23.972,9.631,46.563,26.36,63.032
	c-7.035-1.668-13.844-4.295-20.169-7.808l0,0c-3.06-1.7-6.825,0.485-6.868,3.985l0,0c-0.438,35.612,20.412,67.3,51.646,81.569
	c-0.629,0.015-1.258,0.022-1.888,0.022c-4.951,0-9.964-0.478-14.898-1.421l0,0c-3.446-0.658-6.341,2.611-5.271,5.952l0,0
	c10.138,31.651,37.39,54.981,70.002,60.278c-27.066,18.169-58.585,27.753-91.39,27.753l-10.227-0.006
	c-3.151,0-5.816,2.054-6.619,5.106c-0.791,3.006,0.666,6.177,3.353,7.74c36.966,21.513,79.131,32.883,121.955,32.883
	c37.485,0,72.549-7.439,104.219-22.109c29.033-13.449,54.689-32.674,76.255-57.141c20.09-22.792,35.8-49.103,46.692-78.201
	c10.383-27.737,15.871-57.333,15.871-85.589v-1.346c-0.001-4.537,2.051-8.806,5.631-11.712c13.585-11.03,25.415-24.014,35.16-38.591
	l0,0C411.924,77.126,407.866,72.302,403.632,74.18L403.632,74.18z" />
                        </svg>
                    </a>
                </div>
            </div>
            <!-- Pesan Jika Tidak Ada Hasil Pencarian -->
            <p v-else class="text-center text-gray-500">Tidak ada hasil untuk pencarian.</p>

            <!-- Pagination Controls -->
            <div v-if="totalPages > 1" class="flex justify-center space-x-2 mt-4">
                <button @click="currentPage--" :disabled="currentPage === 1"
                    class="px-3 py-1 bg-gray-300 rounded-lg hover:bg-gray-400 disabled:opacity-50">
                    Previous
                </button>
                <span class="text-gray-700 font-semibold">{{ currentPage }} / {{ totalPages }}</span>
                <button @click="currentPage++" :disabled="currentPage === totalPages"
                    class="px-3 py-1 bg-gray-300 rounded-lg hover:bg-gray-400 disabled:opacity-50">
                    Next
                </button>
            </div>
        </div>
    </div>



    <AppFooter />
</template>

<script>
import AppHeader from '@/components/shared/AppHeader';
import AppFooter from '@/components/shared/AppFooterNonHome.vue';

export default {
    name: 'BotOniel Cari Jokes',
    components: {
        AppHeader,
        AppFooter,
    },
    data() {
        return {
            searchQuery: '', // Kata kunci pencarian
            results: [],     // Hasil pencarian
            currentPage: 1,     // Halaman saat ini
            itemsPerPage: 5,   // Batas item per halaman
        };
    },
    computed: {
        // Menghitung total halaman berdasarkan hasil pencarian
        totalPages() {
            return Math.ceil(this.results.length / this.itemsPerPage);
        },
        // Mengambil item untuk halaman saat ini
        paginatedResults() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.results.slice(start, end);
        }
    },
    methods: {
        async fetchData() {

            this.currentPage = 1;
            // Cek jika input pencarian kosong
            if (!this.searchQuery) {
                this.results = []; // Set results ke kosong jika tidak ada query
                return; // Kembali dari metode
            }

            try {
                // Konfigurasi permintaan data dari Google Sheets
                const SHEET_ID = '1YY8d05SxS6MEU_udvPJJIVYJXDc4TmBnUTc9wVlo6zY';
                const API_KEY = 'AIzaSyAWtgIDV5mt3A9ZjKu-gMsrAZhuTVPvYj8';

                // Mengambil data dari Google Sheets
                const response = await fetch(
                    `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/Jokes!A2:I?key=${API_KEY}`
                );
                const data = await response.json();

                // Memproses hasil pencarian dengan filter dan map
                this.results = data.values
                    .filter(row => {
                        const searchLower = this.searchQuery.toLowerCase();
                        return (
                            (row[0] && row[0].toLowerCase().includes(searchLower)) || // Kolom A (Jokes Video)
                            (row[8] && row[8].toLowerCase().includes(searchLower))    // Kolom I (Jokes Teks)
                        );
                    })
                    .map(row => {
                        // Percabangan berdasarkan kolom A dan I
                        if (row[0]) {
                            // Kolom A memiliki nilai: menggunakan data dari kolom A-E (Jokes Video)
                            return {
                                jokes: row[0],       // Kolom A (Judul jokes video)
                                event: row[2],       // Kolom B (Event video)
                                date: row[1],        // Kolom C (Tanggal video)
                                link: row[4],         // Kolom E (Link video YouTube)
                                linkx: row[7]
                            };
                        } else if (row[8]) {
                            // Kolom A kosong, tapi Kolom I memiliki nilai: menggunakan data dari kolom F-I (Jokes Teks)
                            return {
                                event: row[5],       // Kolom G (Event teks)
                                date: row[6],        // Kolom H (Tanggal teks)
                                link: row[4],         // Kolom J (Link Twitter)
                                linkx: row[7]
                            };
                        }
                        return null;
                    })
                    .filter(item => item); // Menghapus entri null dari hasil
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
    },
    watch: {
        // Menyimpan nilai total halaman saat hasil pencarian diperbarui
        results() {
            this.currentPage = 1;
        }
    }
};
</script>

<style scoped>
/* Tambahkan styling jika diperlukan */
</style>