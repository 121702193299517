<script>
import AppBanner from '@/components/shared/AppBanner';
import AppBannerSSK from '@/components/shared/BannerSSKHome.vue';
import AppBannerBotoniel from '@/components/shared/BannerBotOniel.vue';
import AppBannerWSOP from '@/components/shared/BannerWSOP.vue';
import Button from '../components/reusable/Button.vue';
import AppFooter from '@/components/shared/AppFooter';
import InfoBox from '../components/reusable/InfoBox.vue';


export default {
	name: 'Home',
	components: {
		InfoBox,
		AppBanner,
		Button,
		AppBannerSSK,
		AppBannerBotoniel,
		AppBannerWSOP,
		AppFooter,
	},
};
</script>

<template>
	<div class="container">
		<InfoBox /> <!-- Information box component -->

		<AppBanner />

		<AppBannerSSK />

		<AppBannerBotoniel />

		<AppBannerWSOP />

		<!-- Card Container Footer -->
		<section id="bannerfour" class="flex flex-row items-end">
			<div class="w-full md:ml-10 content-end">
				<div class="mb-5 sm:mb-20 sm:mt-20 justify-center flex">
					<router-link to="/botoniel"
						class="font-bold items-right px-32 py-4 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-xl duration-300"
						aria-label="BotOniel">
						<Button title="BotOniel" />
					</router-link>
				</div>
				<div class="mb-5 sm:mb-20 sm:mt-20 justify-center flex">
					<router-link to="/what-song-oniel-play"
						class="font-bold items-left px-16 py-4 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-xl duration-300"
						aria-label="WSOP">
						<Button title="What Song Oniel Play?" />
					</router-link>
				</div>
				<div class="mb-5 sm:mb-20 sm:mt-20 justify-center flex">
					<router-link to="/general-election"
						class="font-bold items-left px-24 py-4 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-xl duration-300"
						aria-label="WhatsNext">
						<Button title="General Election" />
					</router-link>
				</div>
			</div>
		</section>
	</div>

	<AppFooter class="items-center" />

</template>

<style scoped>
#bannerfour {
	background-image: url(@/assets/images/background-images/oniel-4.jpeg);
	width: 100vw;
	height: 100vh;
	/* Adjust the height as needed */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

@media (max-width: 640px) {

section {
	background-position: right 100px top 0px;
	/* Mengubah posisi background ke kanan pada tampilan mobile */
}
}
</style>
<!--
<section id="bannerfour" class="flex flex-col md:items-end items-center sm:justify-center sm:flex-row">
			<div class="w-full md:ml-10">
				<div class="mb-10 sm:mb-20 sm:mt-20 justify-center flex">
					<router-link to="/botoniel"
						class="font-bold items-right px-36 py-14 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-3xl duration-300"
						aria-label="BotOniel">
						<Button title="BotOniel" />
					</router-link>
				</div>
				<div class="mb-10 sm:mb-20 sm:mt-20 justify-center flex">
					<a href="http://bit.ly/PlaylistnyaOniel"
						class="font-bold items-right px-14 py-14 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-3xl duration-300"
						aria-label="WhatSongOnielPlay">
						<Button title="What Song Oniel Play?" />
					</a>
				</div>
				<div class="mb-10 sm:mb-20 sm:mt-20 justify-center flex">
					<a class="font-bold items-right px-28 py-14 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-3xl duration-300"
						aria-label="WhatsNext">
						<Button title="What's Next?" />
					</a>
				</div>
			</div>
		</section>
	</div>
-->