export const socialLinks = [
	{
		id: 1,
		name: 'Website',
		icon: 'globe',
		url: 'https://jkt48.com/member/detail/id/221/',
	},
	{
		id: 2,
		name: 'Instagram',
		icon: 'instagram',
		url: 'https://instagram.com/jkt48.oniel',
	},
	{
		id: 3,
		name: 'Twitter',
		icon: 'twitter',
		url: 'https://twitter.com/c_onieljkt48',
	},
	{
		id: 4,
		name: 'Tiktok',
		icon: 'music',
		url: 'https://tiktok.com/@onieljkt48',
	},
];
