<script>
import AppHeader from '@/components/shared/AppHeader';
import AppFooter from '@/components/shared/AppFooterNonHome.vue';
import axios from 'axios';

export default {
  name: 'BotOniel Quotes',
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      data: {
        quote: 'Klik button: Niel, Quote Niel',
        eventDate: '',
      },
      isLoading: false,  // Properti untuk melacak status loading
      buttonText: 'Niel, Quote Niel',  // Properti untuk mengubah teks tombol
    };
  },
  computed: {
    tweetUrl() {
      const message = `Quote @C_OnielJKT48 from #BotOniel \n \n"${this.data.quote}" \n${this.data.eventDate}`;
      return `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`;
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true;  // Set status loading menjadi true
      this.buttonText = 'Loading...';  // Ganti teks tombol menjadi "Loading..."
      try {
        const response = await axios.get('https://script.google.com/macros/s/AKfycby9tJLG6uQlYCPgVSay2lZWBIAcAzN2OygFwAtlqK94zMX6shnPNpwhsM5fIxzWlBe1/exec'); // Ganti dengan URL endpoint Anda
        this.data.quote = response.data.quote;
        if (this.data.quote) {
          const formattedDate = this.formatDate(response.data.date);
          this.data.eventDate = `(${response.data.event} - ${formattedDate})`;
        } else {
          this.data.eventDate = '';
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.isLoading = false;  // Set status loading menjadi false
        this.buttonText = 'Niel, Quote Niel';  // Kembalikan teks tombol ke "Get Data"
      }
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('id-ID', options);
    }
  }
};
</script>

<template>
  <!-- App Header -->
  <AppHeader class="items-center mb-24 px-10" /> <!-- hapus mt-24 setelah banner dihapus -->

  <!-- Card Container Start -->
  <div class="container mx-auto">
    <div class="text-center border-b border-primary-light
					dark:border-secondary-dark">
      <p
        class="font-general-semibold text-2xl sm:text-5xl font-semibold text-ternary-dark dark:text-ternary-light mb-4">
        BotOniel Jokes Generator
      </p>
      <h3 class="font-general-regular
					text-center text-secondary-dark
					dark:text-ternary-light
					text-md
					sm:text-xl
					font-normal
					mb-4
				">
        Temukan Jokes Terbaik Oniel di sini!
      </h3>
      <div class="flex justify-center gap-5 mb-10 p-5">
        <router-link to="/botoniel"
          class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
          <i data-feather="book" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
          <span class="text-sm sm:text-lg font-general-medium duration-100">Bot Versi Teks</span>
        </router-link>
        <router-link to="/botoniel/video"
          class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
          <i data-feather="video" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
          <span class="text-sm sm:text-lg font-general-medium duration-100">Bot Versi Video</span>
        </router-link>
        <router-link to="/botoniel/cari"
          class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
          <i data-feather="search" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
          <span class="text-sm sm:text-lg font-general-medium duration-100">Cari Jokes Oniel</span>
        </router-link>
        <a href="https://t.me/botnielbot" target="_blank"
          class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
          <i data-feather="send" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
          <span class="text-sm sm:text-lg font-general-medium duration-100">Akses Bot Telegram</span></a>
      </div>
    </div>
    <div class="container mt-10 sm:gap-10 p-5">
      <div
        class="md:mt-10 h-92 p-5 border border-indigo-200 dark:border-ternary-dark shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
        <div class="flex flex-row justify-center">
          <h1 class="font-general-semibold text-4xl mt-2">{{ this.data.quote }}</h1>
        </div>
        <p class="mt-2 text-xl p-5">{{ this.data.eventDate }}</p>
        <div class="flex justify-center gap-5">
          <a @click="fetchData" :disabled="isLoading"
            class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-xl border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-sky-500 focus:ring-1 focus:ring-sky-900 hover:bg-sky-50 text-white hover:text-sky-500 duration-500">
            <span class="text-sm sm:text-lg font-bold duration-100">{{ buttonText }}</span></a>

          <!-- Tweet Button -->
          <a v-if="data.quote !== 'Klik button: Niel, Quote Niel'" :href="tweetUrl" target="_blank" rel="noopener"
            class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-xl border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-blue-500 focus:ring-1 focus:ring-blue-900 hover:bg-blue-50 text-white hover:text-blue-500 duration-500">
            <svg class="w-5 h-5 mr-2 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M22.46 6c-.77.35-1.6.59-2.46.7a4.28 4.28 0 001.88-2.37 8.72 8.72 0 01-2.76 1.06 4.2 4.2 0 00-7.17 3.83 11.92 11.92 0 01-8.64-4.38 4.19 4.19 0 001.3 5.6 4.22 4.22 0 01-1.91-.53v.05c0 2.07 1.48 3.81 3.45 4.21a4.24 4.24 0 01-1.9.07 4.2 4.2 0 003.92 2.92A8.46 8.46 0 012 18.58a11.92 11.92 0 006.29 1.84c7.55 0 11.68-6.26 11.68-11.68 0-.18-.01-.35-.02-.53A8.36 8.36 0 0022.46 6z" />
            </svg>
            <span class="text-sm sm:text-lg font-bold duration-100">Tweet This</span>
          </a>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<style scoped></style>