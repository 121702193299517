<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			projectName: '#SpecialForCornelia',
			author: 'MRTJam7Lewat12',
		};
	},
};
</script>

<template>
	<div class="flex justify-center items-center text-center bg-transparent">
		<div
			class="font-general-regular text-lg text-ternary-dark dark:text-ternary-light"
		>
			&copy; {{ copyrightDate }}.
			<a
				href="https://specialforcornelia.com"
				target="__blank"
				class="font-general-medium hover:underline hover:text-indigo-600 dark:hover:text-indigo-300 duration-500"
			>
				{{ projectName }}
			</a>
			General Election Campaign Project
			<br> Managed by
			<a
				href="https://x.com/mrtjam7lewat12"
				target="__blank"
				class="font-general-medium text-secondary-dark dark:text-secondary-light hover:underline hover:text-indigo-600 dark:hover:text-indigo-300 duration-500"
				>{{ author }}</a
			> | Part of
			<a
				href="https://labtek.iqbalarauf.my.id"
				target="__blank"
				class="font-general-medium text-secondary-dark dark:text-secondary-light hover:underline hover:text-indigo-600 dark:hover:text-indigo-300 duration-500"
				>Labtek IqbalARauf</a
			>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
